import React, { useState, useEffect } from 'react';
import { useAuth } from "react-oidc-context";
import Loader from './Loader';
import { Link, useNavigate, useParams } from "react-router-dom";
import backArrow from "../assets/img/arrow_back_blue.svg";
import '../styles/search-results.scss';

const SearchResults = (props) => {
  const { isAuthenticated, user } = useAuth();
	const [ showLoader, setShowLoader ] = useState(false);
	const [ token, setToken ] = useState();
	const [ comunicados, setComunicados ] = useState([]);
	const [ procedimientos, setProcedimientos ] = useState([]);
  const [ organigramas, setOrganigramas ] = useState([]);
  const [ faq, setFaq ] = useState([]);
	const [ newQuery, setNewQuery ] = useState('');
  const [ noResults, setNoResults ] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate()
  const { query } = useParams();
  //const breakpoint = 768;
  const breakpoint = process.env.REACT_APP_MOBILE_BREAK_POINT;
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
 

  useEffect(() => {
  	if (isAuthenticated) {
      setToken(user.access_token)
      search(user.access_token)
    }
  }, [props]);

  const search = async (newToken = null) => {
    setShowLoader(true);
  	let thisToken = token;
  	if(newToken){
  		thisToken = newToken;
  	}

  	await fetch(process.env.REACT_APP_BASE_URL + 'contents/search?offset=1&size=40&query=' + query, { headers: { token: thisToken } })
    .then((res) => res.json())
    .then(
      (result) => {
      	if(result.data){
      		const newComunicados = [];
      		const newProcedimientos = [];
          const newOrganigramas = [];
          const newFaq = [];
          if(result.data.results.length === 0){
            setNoResults(true);
          }
          else{
            setNoResults(false);
          }
	        result.data.results.forEach(function(result){
	        	let urlArr = result.url.split('/');
	        	if(urlArr[0] === 'comunicados'){
	        		newComunicados.push(getComunicado(result));
	        	}
	        	else if(urlArr[0] === 'procedimientos'){
	        		newProcedimientos.push(getEntry(result));
	        	}
            else if(urlArr[0] === 'organigramas'){
              newOrganigramas.push(getEntry(result));
            }
            else if(urlArr[0] === 'faq'){
              newFaq.push(getEntry(result));
            }

	        })
	        setComunicados(newComunicados);
	        setProcedimientos(newProcedimientos);
          setOrganigramas(newOrganigramas);
          setFaq(newFaq);
	      }
      },
      (error) => {
        alert(error);
      }
    );

    setShowLoader(false);
  }

  const getComunicado = (hit) => {
  	let dateArr = hit.date.split('/');
  	const title = dateArr[0] + '.' + dateArr[1] + ' | ' + hit.title;
  	return{
  		title: title,
  		lead: hit.body,
  		url: '/c-' + hit.url
  	}
  }

  const getEntry = (hit) => {
  	return{
  		title: hit.title,
  		lead: hit.body,
  		url: '/c-' + hit.url
  	}
  }

  const displayHits = (hits) => {
  	return(
  		hits.map((hit, index) => {
  			return(
  				<Link to={ hit.url } className='hit' key={index}>
			    	<div className='title'>
			    		{ hit.title }
			    	</div>
			    	<div className='description'>
			    		{ hit.lead }
			    	</div>
			    </Link>
			  )
	  	})
  	)
  }

  function goBack(){
    navigate(-1)
  }

  return (
    <div className='search-results'>
    	{
        showLoader &&
        <Loader />
      }
      <img src={backArrow} className="back-button-mobile" onClick={goBack} />
      <div className='search-container'>
      	<input 
      		type='text' 
      		className='search-bar'
      		value={newQuery}
      		onChange={e => setNewQuery(e.target.value)}
          placeholder={ width <= breakpoint ? "Buscar..." : ""}
      	/>
      	<button onClick={() => navigate('/search/' + newQuery)}>
          { width > breakpoint &&
          "Buscar"
          
          }
        </button>
      </div>
      <div className="hits-container">
        <h1>Búsqueda: <b>{ query }</b></h1>
        { noResults &&
          <div>
            <hr />
            <div className="no-results">
              No se encontraron resultados para la búsqueda
            </div>
          </div>
        }
        { comunicados.length > 0 && 
          <div>
            <hr />
            <h2>Comunicados</h2>
            { displayHits(comunicados) }
          </div>
        }
        { procedimientos.length > 0 && 
          <div>
            <hr />
            <h2>Procedimientos</h2>
            { displayHits(procedimientos) }
          </div>
        }

        { faq.length > 0 && 
          <div>
            <hr />
            <h2>F.A.Q.</h2>
            { displayHits(faq) }
          </div>
        }

        { organigramas.length > 0 && 
          <div>
            <hr />
            <h2>Organigramas</h2>
            { displayHits(organigramas) }
          </div>
        }
      </div>
    </div>
  );
};

export default SearchResults;
